import { tns } from "tiny-slider/src/tiny-slider.js"

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

import { applyOverlayAnimation } from './components/navigation.js';
import { applyFormBehaviour } from './components/form.js';
import { applyScrollBehaviour } from './components/animation.js';
import { applyModalBehaviour } from './components/modal.js';
import { contentAnimations } from './components/animation.js';

if (!window.site) window.site = {};

// One time after page loading
window.site.initPage = function () {

};

// Every time after creating new elements. For example: after ajax loading
window.site.initElements = function () {

  const isMobile = $('html').hasClass('mobile');
  const isDesktop = $('html').hasClass('desktop');
  const isSite = $('main').hasClass('app');
  const isHomepage = document.getElementsByClassName('js-is-homepage');

  if (isHomepage.length > 0) {
    $('html').addClass('homepage');
  }

  applyOverlayAnimation();
  applyFormBehaviour();

  if(isDesktop) {
    applyScrollBehaviour();
  }

  applyModalBehaviour();
  contentAnimations();

  document.querySelectorAll('.tns-carousel').forEach((e) => {

    let itemNumber = e.getAttribute('data-items');

    var slider = tns({
      loop: false,
      container: e,
      items: 1,
      responsive: {
        576: {
          items: 2,
          gutter: 20,
        },
        992: {
          items: itemNumber
        }
      },
      slideBy: 'page',
      controlsText: ["",""],
      autoplay: false,
      "mouseDrag": true,
      autoplayButtonOutput: false
    });

  });


  document.querySelectorAll('.js-practitioner-map-view-carousel').forEach((e) => {
    var sliderMapView = tns({
      loop: false,
      container: e,
      items: 3,
      gutter: 30,
      axis: "vertical",
      responsive: {
        576: {
          items: 3
        },
        992: {
          items: 3
        }
      },
      controlsText: ["",""],
      autoplay: false,
      "mouseDrag": true,
      autoplayButtonOutput: false
    });

  });


  /** Accordion **/

  var acc = document.getElementsByClassName("js-accordion-button");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }

  //iOS appHeight CSS property to apply actual window height
  const appHeight = () => {
    const doc = document.documentElement
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  }
  window.addEventListener('resize', appHeight)
  appHeight()

  ScrollTrigger.refresh();

};

window.addEventListener('load', () => {
  window.site.initPage();
  window.site.initElements();

  $('html').addClass('is-ready');
});