import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger.js";

gsap.registerPlugin(ScrollTrigger);

let applyScrollBehaviour = () => {
  /** Parallax Studio Image **/
  let getRatio = el => window.innerHeight / (window.innerHeight + el.offsetHeight);

  gsap.utils.toArray(".js-parallax-bg").forEach((section, i) => {

    section.bg = section.querySelector(".js-parallax-bg-image");

    gsap.fromTo(section.bg, {
      backgroundPosition: () => `50% ${-window.innerHeight * getRatio(section)}px`
    }, {
      backgroundPosition: () => `50% ${window.innerHeight * (1 - getRatio(section))}px`,
      ease: "none",
      scrollTrigger: {
        trigger: section,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        invalidateOnRefresh: true // to make it responsive
      }
    });

  });

  function hide(elem) {
    gsap.set(elem, {autoAlpha: 0});
  }

  /**Animate elements on scroll into view**/

  function animateFrom(elem, direction) {

    direction = direction || 1;
    var x = 0,
        y = direction * 100;
    if(elem.classList.contains("gs_reveal_fromLeft")) {
      x = -100;
      y = 0;
    } else if (elem.classList.contains("gs_reveal_fromRight")) {
      x = 100;
      y = 0;
    }
    elem.style.transform = "translate(" + x + "px, " + y + "px)";
    elem.style.opacity = "0";
    gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
      duration: 1,
      x: 0,
      y: 0,
      autoAlpha: 1,
      ease: "expo",
      overwrite: "auto"
    });
  }

  //Reveal on scroll
  gsap.utils.toArray(".reveal").forEach(function(elem) {
    hide(elem); // assure that the element is hidden when scrolled into view

    ScrollTrigger.create({
      trigger: elem,
      onEnter: function() { animateFrom(elem) },
      onEnterBack: function() { animateFrom(elem, -1) },
      onLeave: function() { hide(elem) } // assure that the element is hidden when scrolled into view
    });
  });

}

let contentAnimations = () => {
  var tl = gsap.timeline();

  tl.from(
      "img",
      {
        opacity: 0,
      },
      "-=.1"
  );

  //Animate Navigsation Overlay
  tl.from(".js-homepage-banner", {
    opacity: 0,
    duration: 0.5
  });

  tl.from(".js-search-form-field", {
    opacity: 0,
    y: 50,
    stagger: 0.1,
    ease: 'power3.inOut',
    duration: 1
  });

}

export {applyScrollBehaviour, contentAnimations};