let applyFormBehaviour = () => {

  /*
Reference: http://jsfiddle.net/BB3JK/47/
*/

  const isMobile = $('html').hasClass('mobile');
  const isSite = $('main').hasClass('app');

  if(!isMobile) {
    $('select').each(function () {
      var $this = $(this), numberOfOptions = $(this).children('option').length;

      $this.addClass('select-hidden');


      if($this.hasClass('select--small')) {
        $this.wrap('<div class="select select--small"></div>');
        $this.after('<div class="select__styled select__styled--small"></div>');
      } else {
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select__styled"></div>');
      }
      var $styledSelect = $this.next('div.select__styled');
      $styledSelect.text($this.children('option').eq(0).text());

      var $list = $('<ul />', {
        'class': 'select__options'
      }).insertAfter($styledSelect);

      for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val()
        }).appendTo($list);
        //if ($this.children('option').eq(i).is(':selected')){
        //  $('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected')
        //}
      }

      var $listItems = $list.children('li');

      $styledSelect.click(function (e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function () {
          $(this).removeClass('active').next('ul.select__options').hide();
        });
        $(this).toggleClass('active').next('ul.select__options').toggle();
      });

      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
        //console.log($this.val());
      });

      $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
      });

    });
  }

};


export {applyFormBehaviour};