import {gsap} from "gsap";

let applyOverlayAnimation = () => {

  // Initial values for overlays

  gsap.set(('.js-mobile-navigation'), {
    scaleX: 1,
    rotation: 0,
    xPercent: 0,
    opacity: 1,
    yPercent: 0,
    transformOrigin: 'right center',
    autoAlpha: 1
  });

  //Init timeline
  let navigation = gsap.timeline({reversed: true, paused: true});

  //Animate Navigation Overlay

  navigation.to(".js-mobile-navigation", {
    opacity: 1,
    xPercent: 0,
    x: 0,
    scaleX: 1,
    ease: 'power4.inOut',
    transformOrigin: 'right center',
    rotation: 0,
    display: "block",
    duration: 1
  });
  navigation.set('.openmenu', {className:"-=closemenu"});
  navigation.set('.openmenu', {className:"+=openmenu"});
  navigation.to('.top', .2, {y:'-9px', transformOrigin: '50% 50%'}, 'burg');
  navigation.to('.bot', .2, {y:'9px', transformOrigin: '50% 50%'}, 'burg');
  navigation.to('.mid', .2, {scale:0, transformOrigin: '50% 50%'}, 'burg');
  navigation.add('rotate');
  navigation.to('.top', .2, {y:'29'}, 'rotate');
  navigation.to('.bot', .2, {y:'-29'}, 'rotate');
  navigation.to('.top', .2, {rotationZ:45, transformOrigin: '50% 50%'}, 'rotate');
  navigation.to('.bot', .2, {rotationZ:-45, transformOrigin: '50% 50%'}, 'rotate');
  navigation.from(".js-mobile-navigation-menu", {opacity: 0, y: 0, duration: 0.5});
  navigation.from(".js-mobile-navigation-close", {opacity: 0, y: 30, duration: 0.5});
  navigation.from(".js-mobile-navigation-logo", {opacity: 0, duration: 0.2});

  $('.js-mobile-navigation-toggle, .js-mobile-navigation-close').click(function () {
    navigation.reversed() ? navigation.play() : navigation.reverse();
  });

};

export {applyOverlayAnimation};