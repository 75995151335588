let applyModalBehaviour = () => {

  const $searchModal = $(".js-search-modal");
  const $searchModalButton = $(".js-search-modal-toggle");
  const $searchModalClose = $(".js-search-modal-close");

  $searchModalButton.on("click", function() {

    $searchModal.addClass('modal__open');
  });

  $searchModalClose.on("click", function() {
    $searchModal.removeClass('modal__open');
  });

  window.onclick = function(event) {

    const $target = $( event.target );

    if ( $target.is( $searchModal )) {
      $searchModal.removeClass('modal__open');
    }
  }

};


export {applyModalBehaviour};